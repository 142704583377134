import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class CanLoadGuard implements CanLoad {

  constructor(private authService: AuthenticationService) { }

  canLoad(): boolean {
    if (this.authService.isAuthenticated() === 'unAuthenticated') {
      return true;
    } else {
      return false;
    }
    // return !this.authService.isAuthenticated();
  }
}
