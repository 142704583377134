import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { finalize, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';
import { LoadingService } from './loading.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  private baseUrl: string;
  private baseUrlAppDev: string;
  private apikey: string;
  private userId: number;
  public static readonly APP_VERSION = '0.1.42';

  private options = {
    preferFrontCamera : false, // iOS and Android
    showFlipCameraButton : false, // iOS and Android
    showTorchButton : false, // iOS and Android
    torchOn: true, // Android, launch with the torch switched on (if   available)
    saveHistory: true, // Android, save scan history (default false)
    prompt : 'Place a barcode inside the scan area', // Android
    resultDisplayDuration: 700, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    orientation : 'portrait', // Android only (portrait|landscape), default unset so it rotates with the device
  };

  constructor(
    private barcodeScanner: BarcodeScanner,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private router: Router
    ) { }

  scan() {
    return this.barcodeScanner.scan(this.options).then((barcodeData: any) => {
      if (barcodeData && barcodeData.text && barcodeData.text !== '') {
        return barcodeData.text;
      } else {
        return this.toastService.presentToast({
          message: 'Codice a barre non valido o non riconosciuto',
          color: 'danger'
        }).then(() => {
          return '';
        });
      }
    }).catch(err => {
      alert('Error' + JSON.stringify(err));
    });
  }

  getMode() {
    this.baseUrl = `https://${this.getUser().host_name}.progestnow.com`;
    this.baseUrlAppDev = `https://${this.getUser().host_name}.progestnow.com/app_dev.php`;
    this.apikey = this.getUser().token_key;
    this.userId = this.getUser().user;
  }

  getConfig() {
    const url = this.baseUrlAppDev + `/shared/table/pn_app_sped_config/single/1?apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(finalize(() => {
    }));
  }

  getUser() {
    return this.authService.getGlobalToken();
  }

  isUserPalmare() {
    let user = this.authService.getGlobalToken();
    console.log(user);
    if(user?.user == 38 || user?.user == 89 || user?.user == 90){
      return true;
    }
    return false;
  }

  getRefreshToken() {
    const url = this.baseUrlAppDev + `/shared/auth/refreshtoken?token=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(finalize(() => {
    }));
  }

  getArticolo(barcode) {
    this.loadingService.present('Ricerca articolo', 0);
    const url = this.baseUrlAppDev + `/documents/cercacodiceabarre?codbar=${barcode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getProdUtility(cidfattura: string, codiceArticolo: string) {
    this.loadingService.present('Ricerca articolo', 0);
    const url = this.baseUrlAppDev + `/documents/invoice/utilities/searchrowindoc?cidfattura=${cidfattura}&codice_articolo=${codiceArticolo}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getList(queryString, page, refresher?) {
    this.loadingService.present('Ricerca lista letture', 0);
    let url = this.baseUrlAppDev + `/shared/table/letturecodici/list?apikey=${this.apikey}&page_number=${page}&page_size=100&output=json`;
    if (queryString && queryString !== '' && queryString !== 'search=[]') {
      url = url + `&${queryString}`;
    }
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getArticoloInfo(barcode: string) {
    this.loadingService.present('Ricerca articolo', 0);
    const url = this.baseUrlAppDev + `/magazzino/magazzino/articolo/dettagliomagazzino?codice=${barcode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getArticoloInfoSped(barcode: string) {
    this.loadingService.present('Ricerca articolo', 0);
    //TODO: cambiare url
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/item/info?codice=${barcode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getForProd(barcodeProd: string, barcodeFor: string) {
    this.loadingService.present('Caricamento', 0);
    const url = this.baseUrlAppDev + `/magazzino/magazzino/articolo/assegnacodiceabarrefornitore?codice_articolo=${barcodeProd}&codiceabarre_fornitore=${barcodeFor}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getLocProd(barcodeProd: string, barcodeLoc: string, forced = '') {
    this.loadingService.present('Caricamento', 0);
    const url = this.baseUrlAppDev + `/magazzino/magazzino/articolo/assegnacodicelocazione?codice_articolo=${barcodeProd}&codice_locazione=${barcodeLoc}${forced}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getLocProdSped(barcodeProd: string, barcodeLoc: string, all: string) {
    this.loadingService.present('Caricamento', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/item/associalocazione?codice_articolo=${barcodeProd}&codice_locazione=${barcodeLoc}&all=${all}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getDoc(cidfattura: string) {
    this.loadingService.present('Ricerca bolla in ingresso', 0);
    const url = this.baseUrlAppDev + `/tabelle/tmp_documenti_testa/loadexistingdoc?cidfattura=${cidfattura}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  checkDocumentoPrelVers(object: any){
    this.loadingService.present('Ricerca documento', 0);
    const params = new URLSearchParams(object);
    const url = this.baseUrlAppDev + `/magazzino/barcodeapp/checkdocumento?${params}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  checkArticoloInDocumento(object: any){
    this.loadingService.present('Ricerca articolo', 0);
    const params = new URLSearchParams(object);
    const url = this.baseUrlAppDev + `/magazzino/barcodeapp/checkarticolo?${params}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  prelevaVersaLocazione(object: any){
    this.loadingService.present('Operazione in corso', 0);
    const params = new URLSearchParams(object);
    const url = this.baseUrlAppDev + `/magazzino/barcodeapp/prelevaversalocazione?${params}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  spostaArticoloLocazione(object: any){
    this.loadingService.present('Sposto articolo', 0);
    const params = new URLSearchParams(object);
    const url = this.baseUrlAppDev + `/magazzino/barcodeapp/spostaarticolotralocazioni?${params}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  checkArticolo(idmagazzino: string){
    this.loadingService.present('Controllo articolo', 0);
    const url = this.baseUrlAppDev + `/shared/table/articoli/single/${idmagazzino}?apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  creaLocazioneInventario(object: any){
    this.loadingService.present('Creo locazione inventario', 0);
    const params = new URLSearchParams(object);
    const url = this.baseUrlAppDev + `/magazzino/barcodeapp/crealocazioneinventario?${params}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getControlliInventario(){
    this.loadingService.present('Carico controlli inventario', 0);
    const url = this.baseUrlAppDev + `/shared/app/getcontrolliinventario?apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getControlliInventarioDettaglio(id: string, idmagazzino?: string){
    this.loadingService.present('Carico dettaglio', 0);
    const url = this.baseUrlAppDev + `/shared/app/getcontrolliinventario/detail?id=${id}&idmagazzino=${idmagazzino}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getDepositiList(){
    this.loadingService.present('Carico depositi', 0);
    const url = this.baseUrlAppDev + `/shared/app/depositi/list?apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  controlloInventarioNew(object: any) {
    this.loadingService.present('Inserisco controllo', 0);
    const url = this.baseUrlAppDev + `/shared/app/controlliinventario/add`;

    object.apikey = this.apikey;

    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  controlloInventarioUpdate(object: any) {
    this.loadingService.present('Aggiorno controllo', 0);
    const url = this.baseUrlAppDev + `/shared/app/controlliinventario/update`;

    object.apikey = this.apikey;

    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  searchCodArtByGenericCode(code: string){
    this.loadingService.present('Cerco articolo', 0);
    const url = this.baseUrlAppDev + `/magazzino/magazzino/articolo/searcharticolobygenericcode?codice=${code}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  deleteControlliInventarioDetail(id: string){
    this.loadingService.present('Elimino dettaglio', 0);
    const url = this.baseUrlAppDev + `/shared/app/controlliinventario/detail/delete?id=${id}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  controlloInventarioAddRow(object: any){
    this.loadingService.present('Aggiorno quantità', 0);
    const url = this.baseUrlAppDev + `/shared/app/controlliinventario/detail/add`;

    object.apikey = this.apikey;

    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  postDoc(idmagazzino, qta, update = false) {
    this.loadingService.present('Caricamento', 0);

    let payload = {};

    if (update) {
      payload = {
        idmagazzino,
        qta,
        update: '1'
      };
    } else {
      payload = {
        idmagazzino,
        qta
      };
    }

    const url = this.baseUrlAppDev + `/tabelle/tmp_documenti_testa/addrow?apikey=${this.apikey}&output=json`;
    return this.http.post(encodeURI(url), JSON.stringify(payload)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  saveDoc() {
    this.loadingService.present('Salvataggio bolla', 0);
    const url = this.baseUrlAppDev + `/tabelle/tmp_documenti_testa/fromtmptodoc?apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  saveInvoices(){
    this.loadingService.present('Trasmetto fatture', 0);
    const url = this.baseUrlAppDev + `/tabelle/letturecodici/registraletture?apikey=${this.apikey}&output=json`;

    let payload = {
      idcausale: 'VEN'
    };

    return this.http.post(encodeURI(url), JSON.stringify(payload)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getDDTPickingList(cidfattura: string) {
    this.loadingService.present('Ricerca bolla in uscita', 0);
    const url = this.baseUrlAppDev + `/documents/packinglist/api/getddtpackinglist?cidfattura=${cidfattura}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getContenutoScatola(packinglistid: string, ncollo: string) {
    this.loadingService.present('Recupero contenuto', 0);
    const url = this.baseUrlAppDev + `/documents/packinglist/api/getcontenutoscatola?packinglistid=${packinglistid}&ncollo=${ncollo}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  scanArticoloDDTUscita(packinglistid: string, idmagazzino:string, qta: number) {
    this.loadingService.present('Ricerca articolo', 0);
    const url = this.baseUrlAppDev + `/documents/packinglist/api/updateqtaprelevata?packinglistid=${packinglistid}&idmagazzino=${idmagazzino}&qta=${qta}&apikey=${this.apikey}&output=json`;

    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  dimensioniCollo(collo_id: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/collo/dimensioni?collo_id=${collo_id}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  setColloDimensioni(object: any) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/collo/dimensioni?apikey=${this.apikey}&output=json`;
    
    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  misurazioneItem(item_id: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/misurazioni/item?item_id=${item_id}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  misurazioneItemMisura(collo_id: string, device: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/misurazioni/item/misura?collo_id=${collo_id}&device=${device}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  misurazioneItemMisuraIstanziaCollo(item_id: string, device: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/misurazioni/item/misuraeistanziacollo?item_id=${item_id}&device=${device}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  searchPratica(praticaCode: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/searchpratica?pratica_code=${praticaCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  searchPraticaColli(praticaCode: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/searchcolliorderedbylocation?pratica_code=${praticaCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  prelevaDeprelevaCollo(itemColloId: string, praticaCode: string, status: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/colli/preleva?item_collo_code=${itemColloId}&pratica_code=${praticaCode}&status=${status}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  prelevaDeprelevaColliMupltipli(object: any) {
    this.loadingService.present('Invio colli...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/colli/preleva_multi?apikey=${this.apikey}&output=json`;
    
    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  clearImballaggio(praticaCode: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/imballaggioclear?pratica_code=${praticaCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  clearPrelievo(praticaCode: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/prelievoclear?pratica_code=${praticaCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  checkIfColloCanBeAdded(praticaCode: string, codiceImballaggio: string, itemColloId: string, status: string) {
    this.loadingService.present('Controllo collo', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/colli/preimballaggio?pratica_code=${praticaCode}&codiceimballaggio=${codiceImballaggio}&item_collo_code=${itemColloId}&status=${status}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  addColloToImbalaggio(praticaCode: string, codiceImballaggio: string, itemColloId: string, status: string) {
    this.loadingService.present('Aggiungo collo', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/colli/imballaggio?pratica_code=${praticaCode}&codiceimballaggio=${codiceImballaggio}&item_collo_code=${itemColloId}&status=${status}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  addColliMultiToImbalaggio(object: any) {
    this.loadingService.present('Aggiungo colli', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/colli/imballaggio_multi?apikey=${this.apikey}&output=json`;
    
    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  addColliToImbalaggio(object: any) {
    this.loadingService.present('Imballo selezione', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/colli/imballaggiomultiplo?apikey=${this.apikey}&output=json`;

    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  imballaggioAutomatico(praticaCode: string) {
    this.loadingService.present('Imballo automatico', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/imballaggioautomatico?pratica_code=${praticaCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  deleteImballaggio(codiceImballaggio: string) {
    this.loadingService.present('Elimino imballaggio', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/imballaggiodelete?codiceimballaggio=${codiceImballaggio}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getImballiAdvanced() {
    this.loadingService.present('Ricerca imballi', 0);
    const url = this.baseUrlAppDev + `/shared/table/articoli/list?search=[{"field":"articoli.idtipologia","op":"=","type":"string","value":"IMB"}]&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getImballiSpedAdvanced() {
    this.loadingService.present('Ricerca imballi', 0);
    const url = this.baseUrlAppDev + `/shared/table/zz_sped_imballi/list?apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getImballaggioDimensioni(imballaggioCode: string) {
    this.loadingService.present('Recupero dimensioni', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/getimballaggiodimensioni?imballo_code=${imballaggioCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  setPraticaImballaggio(obj: any) {
    this.loadingService.present('Aggiorno pratica imballo', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/imballaggio?codice=${obj.codice}&imballo_code=${obj.imballo_code}&lunghezza=${obj.lunghezza}&larghezza=${obj.larghezza}&altezza=${obj.altezza}&peso_imballaggio=${obj.peso_imballaggio}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  misuraImballaggio(obj: any) {
    this.loadingService.present('Aggiorno dimensioni', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/misurazioni/imballaggio/misura?imballaggio_code=${obj.codice}&device=${obj.device}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  addColloAdvanced(obj: any) {
    this.loadingService.present('Aggiungo imballaggio', 0);
    const url = this.baseUrlAppDev + `/documents/packinglist/api/addcollo?cidfattura=${obj.cidfattura}&cidimballo=${obj.cidimballo}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  addProdottoAdvanced(obj: any) {
    this.loadingService.present('Aggiungo prodotto scatola', 0);
    const url = this.baseUrlAppDev + `/documents/packinglist/api/addprodottotoscatola?packinglistid=${obj.packinglistid}&idmagazzino=${obj.idmagazzino}&qta=${obj.qta}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  uscitaImballaggio(obj: any) {
    this.loadingService.present('Operazione in corso...', 0);
    const params = new URLSearchParams(obj);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/preuscita?${params}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getVettori() {
    this.loadingService.present('Recupero vettori', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/getvettori?apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  saveDatiUscita(object: any) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/savedatiuscita?apikey=${this.apikey}&output=json`;

    object.apikey = this.apikey;

    return this.http.post(url, object).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  clearImballaggiiUsciti(praticaCode: string) {
    this.loadingService.present('Operazione in corso...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/imballaggiusciticlear?pratica_code=${praticaCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  deleteImballaggioUscito(codiceImballaggio: string) {
    this.loadingService.present('Elimino imballaggio', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/imballaggiouscitodelete?codiceimballaggio=${codiceImballaggio}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  getImballaggiDatiUscita(praticaCode: string) {
    this.loadingService.present('Recupero valori...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/getimballaggiodatiuscita?pratica_code=${praticaCode}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  printEtichetta(pratica: string) {
    this.loadingService.present('Stampo...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/printetichette?pratica_code=${pratica}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  printImballaggio(pratica: string, imballo: string) {
    this.loadingService.present('Stampo...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/pratica/printetichette?pratica_code=${pratica}&codiceimballaggio=${imballo}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  printItem(item: string) {
    this.loadingService.present('Stampo...', 0);
    const url = this.baseUrlAppDev + `/personalizzati/spedizionieri/app/item/printetichette?item_id=${item}&apikey=${this.apikey}&output=json`;
    return this.http.get(encodeURI(url)).pipe(
      finalize(() => {
        this.loadingService.dismiss();
      })
    );
  }

  logout() {
    this.alertService.presentAlert({
      header: `Sei sicuro di voler fare il logout?`,
      message: '',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'OK',
          role: 'confirm',
          cssClass: 'primary',
          handler: () => {
            this.router.navigate(['login']);
            this.authService.logout();
          }
        }
      ]
    });
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
