import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

const { Storage } = Plugins;

const USER_TOKEN = 'user-token';
const LAST_URL = 'user-last-url';
type authType = 'unAuthenticated' | 'authenticated' | 'undetermined';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticationState = new BehaviorSubject<authType>('undetermined');
  loading: HTMLIonLoadingElement;
  globalUser: any;

  constructor(private http: HttpClient, private plt: Platform) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkCredentials(host: string, username: string, password: string): Observable<any> {
    const url = `https://${host}.progestnow.com/app_dev.php/shared/auth/forceauth?login=${username}&password=${password}&output=json`;
    // TODO: manage the error better
    return this.http.get(encodeURI(url))
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return 'e';
      }),
      map(results => {
        return results;
      }),
      tap(() => {
      })
    );
  }

  login(key: string, host: string, userId: number, employee: string, defaultPage: string) {
    const user = {
      token_key: key,
      host_name: host,
      user: userId,
      employee,
      defaultPage
    };
    this.globalUser = user;
    this.setToken(user);
  }

  async updateToken(token: string){
    this.globalUser.token_key = token;
    await this.setToken(this.globalUser);
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  getGlobalToken(): any {
    return this.globalUser;
  }

  async logout() {
    await Storage.clear();
    this.resetGlobaluser();
    this.setAuthType('unAuthenticated');
  }

  // TODO: rename to loadStoredToken
  async checkToken() {
    const res = await Storage.get({ key: USER_TOKEN });
    if (res && res.value) {
      this.globalUser = JSON.parse(res.value);
      this.setAuthType('authenticated');
    } else {
      this.setAuthType('unAuthenticated');
    }
  }

  async getToken(): Promise<any> {
    const res = await Storage.get({ key: USER_TOKEN });
    if (res && res.value) {
      return res.value;
    }
  }

  async setToken(user: any) {
    await Storage.set({
      key: USER_TOKEN,
      value: JSON.stringify(user)
    });
    this.setAuthType('authenticated');
  }

  async getRoute(): Promise<any[]> {
    const res = await Storage.get({ key: LAST_URL });
    if (res && res.value) {
      return JSON.parse(res.value);
    }else{
      return null;
    }
  }

  async saveRoute(data: any[]) {
    await Storage.set({
      key: LAST_URL,
      value: JSON.stringify(data)
    });
  }

  private resetGlobaluser() {
    this.globalUser = {
      token_key: '',
      host_name: '',
      user: 0,
      employee: ''
    };
  }

  private setAuthType(authenticationState: authType) {
    if (['unAuthenticated', 'authenticated', 'undetermined'].indexOf(authenticationState) < 0) {
      throw new Error(`${ authenticationState } is not allowed`);
    }
    this.authenticationState.next(authenticationState);
  }
}
