import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { CanLoadGuard } from './services/can-load-guard.service';

const routes: Routes = [
  {
    path: 'login',
    canLoad: [CanLoadGuard],
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'info-prod',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./info-prod/info-prod.module').then( m => m.InfoProdPageModule)
  },
  {
    path: 'cod-loc',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./cod-loc/cod-loc.module').then( m => m.CodLocPageModule)
  },
  {
    path: 'preleva-per-ship',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./preleva-per-ship/preleva-per-ship.module').then( m => m.PrelevaPerShipPageModule)
  },
  {
    path: 'preleva-per-ship-info/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./preleva-per-ship-info/preleva-per-ship-info.module').then( m => m.PrelevaPerShipInfoPageModule)
  },
  {
    path: 'imballa-per-ship',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./imballa-per-ship/imballa-per-ship.module').then( m => m.ImballaPerShipPageModule)
  },
  {
    path: 'imballa-per-ship-info/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./imballa-per-ship-info/imballa-per-ship-info.module').then( m => m.ImballaPerShipInfoPageModule)
  },
  {
    path: 'modal-imballa-dimensioni/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal-imballa-dimensioni/modal-imballa-dimensioni.module').then( m => m.ModalImballaDimensioniPageModule)
  },
  {
    path: 'imballa-per-ship-info-add/:pratica',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./imballa-per-ship-info-add/imballa-per-ship-info-add.module').then( m => m.ImballaPerShipInfoAddPageModule)
  },
  {
    path: 'imballa-per-ship-info-add/:pratica/:codiceimballo',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./imballa-per-ship-info-add/imballa-per-ship-info-add.module').then( m => m.ImballaPerShipInfoAddPageModule)
  },
  {
    path: 'uscita-imballaggi',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./uscita-imballaggi/uscita-imballaggi.module').then( m => m.UscitaImballaggiPageModule)
  },
  {
    path: 'uscita-imballaggi-info/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./uscita-imballaggi-info/uscita-imballaggi-info.module').then( m => m.UscitaImballaggiInfoPageModule)
  },
  {
    path: 'modal-uscita-imballaggi-dati',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal-uscita-imballaggi-dati/modal-uscita-imballaggi-dati.module').then( m => m.ModalUscitaImballaggiDatiPageModule)
  },
  {
    path: 'modal-colli-ordinati',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal-colli-ordinati/modal-colli-ordinati.module').then( m => m.ModalColliOrdinatiPageModule)
  },
  {
    path: 'misurazione-item',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./misurazione-item/misurazione-item.module').then( m => m.MisurazioneItemPageModule)
  },
  {
    path: 'misurazione-item-info/:iditem',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./misurazione-item-info/misurazione-item-info.module').then( m => m.MisurazioneItemInfoPageModule)
  },
  {
    path: 'zebra-settings',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./zebra-settings/zebra-settings.module').then( m => m.ZebraSettingsPageModule)
  },
  {
    path: 'modal-misurazione-dimensioni/:idcollo',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modal-misurazione-dimensioni/modal-misurazione-dimensioni.module').then( m => m.ModalMisurazioneDimensioniPageModule)
  },
  { 
    path: '**', 
    redirectTo: 'dashboard', 
    pathMatch: 'full' 
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
