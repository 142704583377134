import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private presented = false;

  constructor(public loadingController: LoadingController) { }

  async present(message: string, duration = 2000) {
    console.log("present");
    if (!this.presented) {
      this.presented = true;
      return await this.loadingController.create({
        message,
        duration
      })
      .then(a => {
        a.present().then(() => {
          if (!this.presented) {
            a.dismiss().then(() => {
              this.presented = false;
            });
          }
        });
      });
    }
  }

  async dismiss() {
    console.log("dismiss");
    this.presented = false;
    return await this.loadingController.dismiss();
  }
}
