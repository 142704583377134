import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  // tslint:disable: max-line-length

  toast: HTMLIonToastElement;
  private presented = false;

  constructor(public toastController: ToastController) { }

  /**
   * @example
   * const buttons = [{
   *    side: 'start',
   *    icon: 'arrow-forward',
   *    text: 'Go to it!',
   *    handler: () => { this.mySassyCallback(myPreciousParameter); }
   * },
   * {
   *    text: 'Dismiss',
   *    role: 'cancel',
   *    handler: () => {}
   *   }];
   * this.toastService.presentToast({message: msg.additionalData, handlers: buttons});
   * Create and present the toast overlay
   * @param param0 TODO: interface for toast params type
   */
  async presentToast({message, duration = 3000, isDismissable = true, closeButtonText = 'OK', color = 'primary', handlers = []}:
  {message: string; duration?: number; isDismissable?: boolean; closeButtonText?: string; color?: string; handlers?: any[] }) {

    const buttons: any[] = [];

    if (isDismissable) {
      buttons.push({
          text: closeButtonText,
          role: 'cancel'
      });
    }

    buttons.push(handlers);

    this.toast = await this.toastController.create({
      message,
      buttons,
      duration,
      position: 'bottom',
      color
    });

    if (!this.presented) {
      this.presented = true;
      this.toast.present();
    }

    return await this.toast.onDidDismiss().then((roles) => {
      this.presented = false;
      return roles;
    });
  }
}
