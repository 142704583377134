import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { CustomService } from './services/custom.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private authService: AuthenticationService,
    private customService: CustomService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.authService.authenticationState.subscribe(state => {
        if (state === 'authenticated') {
          this.statusBar.styleDefault();
          this.splashScreen.hide();
          this.customService.getMode();
          if(this.router.url == '/login' || this.router.url == 'login'){
            this.authService.saveRoute(['dashboard']);
            this.router.navigate(['dashboard']);
          }
        } else if (state === 'unAuthenticated') {
          this.splashScreen.hide();
          this.router.navigate(['login']);
        } else if (state === 'undetermined') {
          this.splashScreen.show();
          this.authService.checkToken();
        }
      });
    });
  }
}
