import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alert: HTMLIonAlertElement;
  private presented = false;

  constructor(private alertController: AlertController) { }

  /**
   * Present alert return promise
   * @example
   * this.alertService.presentAlert({
   *  header: `Procedere?`,
   *   message: message,
   *   buttons: [
   *     {
   *       text: 'Cancel',
   *       role: 'cancel',
   *       cssClass: 'secondary',
   *       handler: () => {
   *       }
   *     }, {
   *       text: 'Okay',
   *       role: 'confirm',
   *       cssClass: 'primary',
   *       handler: () => {
   *       }
   *     }
   *   ]
   * })
   * @param param0 HTMLIonAlertElement
   */
  async presentAlert({header, subHeader = '', message, cssClass = 'my-custom-class', buttons = [], inputs = []}:
  {header: string, subHeader?: string, message: string, cssClass?: string, buttons?: any[], inputs?: any[]}) {
    this.alert = await this.alertController.create({
      header,
      message,
      subHeader,
      buttons,
      inputs,
      cssClass
    });

    if (!this.presented) {
      this.presented = true;
      this.alert.present();
    }

    return this.alert.onDidDismiss().then((roles) => {
      this.presented = false;
      return roles;
    });
  }

}
